<template>
  <div>
    <v-navigation-drawer
      v-model="drawerOpen"
      app
      class="light-green lighten-1"
      width="280"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            <wot-lazy-image :fileName="'logo.png'"/>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider/>
      <v-list-item-subtitle
        class="mt-3 ml-5"
        @click="setChangelogDialog(true)"
        style="cursor: pointer"
      >
        V{{ version }}
      </v-list-item-subtitle>
      <menu-items v-if="user" :key="user.id"/>
    </v-navigation-drawer>
    <ChangelogDialog />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import WotLazyImage from '@/components/wot/WotLazyImage.vue';
import { version } from '../../../package.json';

export default {
  components: {
    WotLazyImage,
    MenuItems: () => import(/* webpackChunkName: "menu-items" */ '@/components/menu/MenuItems.vue'),
    ChangelogDialog: () => import(/* webpackChunkName: "menu-items" */ '@/components/menu/ChangelogDialog.vue'),
  },
  data: () => ({
    version,
  }),
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters(['isDrawerOpen']),
    drawerOpen: {
      get() {
        return this.isDrawerOpen;
      },
      set(value) {
        this.setDrawer(value);
      },
    },
  },
  methods: {
    ...mapMutations(['setDrawer', 'setChangelogDialog']),
  },
};
</script>
